import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'vvc-link-block',
  templateUrl: './link-block.component.html',
  styleUrls: ['./link-block.component.less'],
  standalone: true,
  imports: [CommonModule, NzIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkBlockComponent {
  @Input() imgName!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() url!: string;

  get imgType(): string {
    return `img:${this.imgName}`;
  }
}
