<div class="block">
  <h3 class="block-title">
    <span
      nz-icon
      class="block-title-icon"
      *ngIf="titleIcon !== undefined"
      [nzType]="titleIcon"
    ></span>
    {{ title }}
  </h3>
  <a
    nz-button
    nzType="text"
    class="block-text"
    *ngFor="let item of data; trackBy: identify"
    (click)="select(item)"
  >
    {{ item.title }}
  </a>
  <a nz-button nzType="link" class="block-link" (click)="expand()">See all</a>
</div>
