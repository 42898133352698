import { BehaviorSubject, filter, interval, Observable, of, switchMap, takeUntil, tap } from 'rxjs';

import { SEC_IN_MS } from '@vvc/constants';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

export function getPercentageInterval(
  destroyer: Observable<NzSafeAny>,
  initialTimestamp?: number,
  percentsBeforeEndless = 80,
  limit = 99,
  secondsUntilEndless = 240
): Observable<number> {
  const initialOffset = calculateInitialOffset(percentsBeforeEndless, 0, limit, secondsUntilEndless, initialTimestamp);
  const percentage = new BehaviorSubject<number>(initialOffset);
  const intervalValue$ = new BehaviorSubject<number>(
    calculateNextInterval(secondsUntilEndless, initialOffset - percentsBeforeEndless, limit, percentsBeforeEndless)
  );

  intervalValue$
    .asObservable()
    .pipe(
      switchMap(val => (val == Infinity ? of(null) : interval(val))),
      takeUntil(destroyer),
      filter(val => val !== null),
      tap(() => {
        if (percentage.value >= percentsBeforeEndless && percentage.value < limit) {
          intervalValue$.next(
            calculateNextInterval(
              secondsUntilEndless,
              percentage.value - percentsBeforeEndless + 1,
              limit,
              percentsBeforeEndless
            )
          );
        }
        percentage.next(percentage.value + 1);
      })
    )
    .subscribe();

  return percentage.asObservable();
}

function calculateInitialOffset(
  percentsBeforeEndless: number,
  offset: number,
  limit: number,
  secondsUntilEndless: number,
  initialTimestamp?: number
): number {
  let initialOffset = 0;
  while ((initialTimestamp || 0) > 0 && initialOffset < limit) {
    initialTimestamp! -= calculateNextInterval(secondsUntilEndless, offset, limit, percentsBeforeEndless);
    initialOffset++;
    if (initialOffset >= percentsBeforeEndless) {
      offset++;
    }
  }
  return initialOffset || 1;
}

function calculateNextInterval(
  secondsUntilEndless: number,
  offset: number,
  limit: number,
  percentsBeforeEndless: number
): number {
  return (
    (secondsUntilEndless * SEC_IN_MS) /
    ((1 - (offset > 0 ? offset : 0) / (limit - percentsBeforeEndless)) * percentsBeforeEndless)
  );
}
