import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';

import { PhoneNumberValidator } from '@vvc/utils';
import * as intlTelInput from 'intl-tel-input';

/**
 * Handler applicable to nz-form-control component.
 * Provides attributes to a component and encapsulates the templates bindings from the child component.
 *
 * @export
 * @class ControlHandlerDirective
 */
@Directive({
  selector: '[vvcPhone]',
  standalone: true
})
export class PhoneDirective implements OnInit {
  private intlTelInputPlugin?: intlTelInput.Plugin;

  constructor(private input: ElementRef<HTMLInputElement>, private controlDirective: FormControlDirective) {}

  @HostListener('countrychange')
  telCountryChange(): void {
    this.controlDirective.control.setValidators(
      PhoneNumberValidator(this.intlTelInputPlugin?.getSelectedCountryData().iso2)
    );
    this.controlDirective.control.updateValueAndValidity();
  }

  ngOnInit(): void {
    if (this.input.nativeElement.type === 'tel') {
      const input = this.input?.nativeElement;
      this.intlTelInputPlugin = intlTelInput(input, {
        nationalMode: false,
        separateDialCode: true,
        customPlaceholder: selectedselectedCountryPlaceholder => {
          return selectedselectedCountryPlaceholder.replace(/\d/g, 'X');
        }
      });
      this.controlDirective.control.getRawValue = () => this.intlTelInputPlugin?.getNumber() || null;
    }
  }
}
