import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, AbstractControlOptions, ValidationErrors } from '@angular/forms';
import { map, merge, Observable, takeWhile, tap } from 'rxjs';

import { LoginRequest } from '../interfaces';

export enum LoginErrorStatuses {
  UNAUTHORIZED = 401,
  TOO_MANY_REQUESTS = 429
}

export const loginErrorTypes: Record<LoginErrorStatuses, string> = {
  [LoginErrorStatuses.UNAUTHORIZED]: 'loginfailed',
  [LoginErrorStatuses.TOO_MANY_REQUESTS]: 'logintemporaryblocked'
};

export const loginErrorMessages: Record<LoginErrorStatuses, string> = {
  [LoginErrorStatuses.UNAUTHORIZED]: 'Wrong username or password, please try again...',
  [LoginErrorStatuses.TOO_MANY_REQUESTS]: 'You have failed many times, please retry later...'
};

export function LoginFormValidators(sharedError: Observable<HttpErrorResponse>): AbstractControlOptions {
  let submitted = false;
  return {
    asyncValidators: (control: AbstractControl<LoginRequest>): Observable<ValidationErrors | null> => {
      return merge(control.valueChanges, sharedError).pipe(
        takeWhile(data => !('name' in data) || data.name !== 'HttpErrorResponse', true),
        tap(data => {
          if ('name' in data && data.name === 'HttpErrorResponse') {
            submitted = true;
          }
        }),
        map(data => validateLogin(control, data))
      );
    },
    validators: (control: AbstractControl): ValidationErrors | null => {
      removeLoginErrors(control, 'password');
      removeLoginErrors(control, 'username');
      if (!submitted) {
        control.get('username')?.updateValueAndValidity({ onlySelf: true });
        control.get('password')?.updateValueAndValidity({ onlySelf: true });
      }
      submitted = false;
      return null;
    }
  };
}

function removeLoginErrors(controls: AbstractControl, controlName: string): void {
  const errors = controls.get(controlName)?.errors;
  if (errors) {
    delete errors[LoginErrorStatuses.UNAUTHORIZED];
    delete errors[LoginErrorStatuses.TOO_MANY_REQUESTS];
    controls.get(controlName)?.setErrors({ ...errors });
  }
}

function validateLogin(control: AbstractControl, data: LoginRequest | HttpErrorResponse): null {
  if ('name' in data && data.name === 'HttpErrorResponse') {
    throw handleLoginError(control, data);
  }
  return handleLoginChange(control);
}

function handleLoginError(control: AbstractControl, { error, status }: HttpErrorResponse): null {
  const failStatus = status in LoginErrorStatuses ? (status as LoginErrorStatuses) : LoginErrorStatuses.UNAUTHORIZED;
  control.get('password')?.setErrors({ [loginErrorTypes[failStatus]]: error?.detail || true });
  control.get('username')?.setErrors({ [loginErrorTypes[failStatus]]: error?.detail || true });
  return null;
}

function handleLoginChange(control: AbstractControl): null {
  control.get('password')?.setErrors(null);
  control.get('username')?.setErrors(null);
  return null;
}
