import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { environment } from '@vvc-env/environment';
import { AppInitializerProvider } from '@vvc/app-initilizer';
import { AppInterceptor } from '@vvc/app-interceptor';
import { APP_ROUTES } from '@vvc/app-routing';
import { AppComponent } from '@vvc/app.component';
import { vvcNgZorroConfig } from '@vvc/constants';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

window.Ververica = {
  ga: environment.googleAnalyticsId
};
if (environment.production) {
  enableProdMode();
}

registerLocaleData(en);

bootstrapApplication(AppComponent, {
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: NZ_CONFIG, useValue: vvcNgZorroConfig },
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(NzNotificationModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(RouterModule.forRoot([...APP_ROUTES])),
    AppInitializerProvider
  ]
}).catch(err => console.error(err));
