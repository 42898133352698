import { Directive, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';

import { AbstractVvcControlDirective, ControlTemplatesComponent } from '@vvc/components';
import { VVC_CONTROL_ACCESSOR } from '@vvc/constants';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzFormControlComponent } from 'ng-zorro-antd/form';

/**
 * Handler applicable to nz-form-control component.
 * Provides attributes to a component and encapsulates the templates bindings from the child component.
 *
 * @export
 * @class ControlHandlerDirective
 */
@Directive({
  selector: '[vvcTemplateBinder]',
  standalone: true
})
export class TemplateBinderDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private formControl: NzFormControlComponent,
    private viewContainer: ViewContainerRef,
    @Inject(VVC_CONTROL_ACCESSOR) private controlComponent: AbstractVvcControlDirective<NzSafeAny>
  ) {}

  ngOnInit(): void {
    this.initTemplates();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initTemplates(): void {
    const templates = this.viewContainer.createComponent(ControlTemplatesComponent);
    templates.instance.errors = [];
    templates.instance.defaultErrorText = '';
    templates.instance.hint = '';
    templates.instance.control = this.controlComponent.control;
    this.controlComponent.templateParams$
      .asObservable()
      .pipe(
        tap(value => {
          templates.instance.hint = value.hint;
          templates.instance.errors = value.errors;
          templates.instance.defaultErrorText = value.defaultErrorText;
          this.formControl.nzErrorTip =
            value.errors.length || value.defaultErrorText ? templates.instance.errorTips : undefined;
          this.formControl.nzExtra = value.hint ? templates.instance.hintTemplate : undefined;
          templates.instance.changeDetector.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
