<div class="link-block">
  <div class="link-icon">
    <span nz-icon [nzType]="imgType"></span>
  </div>
  <div class="link-block-text">
    <a class="link" [href]="url" target="_blank">
      {{ title }}
      <span nz-icon nzType="img:link-out" class="link-url"></span>
    </a>
    <div class="link-description">{{ description }}</div>
  </div>
</div>
