import { APP_INITIALIZER } from '@angular/core';

import { RefreshService } from '@vvc/services/refresh.service';

export const AppInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: (refreshService: RefreshService) => {
    refreshService.setup();
  },
  deps: [RefreshService]
};
