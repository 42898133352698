import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, switchMap, tap, timer } from 'rxjs';

import {
  Cookies,
  DO_NOTIFY_SUCCESS,
  EXCLUDE_ERRORS,
  EXCLUDE_EXPIRED,
  EXPIRATION_TIME_SHIFT,
  IS_PUBLIC_API,
  MIN_IN_MS,
  SEC_IN_MS
} from '@vvc/constants';
import {
  LoginRequest,
  TokenResponse,
  ResetPasswordRequest,
  RefreshRequest,
  ForgetPasswordRequest,
  ChangePasswordRequest
} from '@vvc/interfaces';
import { CookiesService } from '@vvc/services';

export const authRoutes = {
  TOKENS: '/auth/tokens',
  FORGET_PASSWORD: '/auth/passwordResetRequest',
  RESET_PASSWORD: '/auth/passwordSetRequest',
  CHANGE_PASSWORD: '/auth/passwordChangeRequest'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private userIsLogged = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();
  private timer$ = new Subject<void>();

  private readonly successForgetPasswordMessage = 'A reset password link has been sent to your email address.';
  private readonly successResetPasswordMessage = 'The password has been updated.';
  private readonly successChangePasswordMessage = 'Password successfuly changed';

  get isUserLogged(): boolean {
    return this.userIsLogged.value;
  }

  get isUserLogged$(): BehaviorSubject<boolean> {
    return this.userIsLogged;
  }

  constructor(private http: HttpClient, private cookiesService: CookiesService) {
    const token = this.cookiesService.getCookie(Cookies.ACCESS_TOKEN);
    const expirationDate = this.cookiesService.getCookie(Cookies.EXPIRATION_TIME);
    if (token && expirationDate) {
      this.userIsLogged.next(true);
      this.initTimer(+expirationDate);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  forgetPassword(payload: ForgetPasswordRequest): Observable<void> {
    return this.http.post<void>(authRoutes.FORGET_PASSWORD, payload, {
      context: new HttpContext().set(IS_PUBLIC_API, true).set(DO_NOTIFY_SUCCESS, this.successForgetPasswordMessage)
    });
  }

  changePassword(payload: ChangePasswordRequest): Observable<void> {
    return this.http.post<void>(authRoutes.CHANGE_PASSWORD, payload, {
      context: new HttpContext().set(DO_NOTIFY_SUCCESS, this.successChangePasswordMessage)
    });
  }

  resetPassword(payload: ResetPasswordRequest): Observable<void> {
    return this.http.post<void>(authRoutes.RESET_PASSWORD, payload, {
      context: new HttpContext().set(IS_PUBLIC_API, true).set(DO_NOTIFY_SUCCESS, this.successResetPasswordMessage)
    });
  }

  token(payload: RefreshRequest | LoginRequest): Observable<TokenResponse> {
    return this.http
      .post<TokenResponse>(authRoutes.TOKENS, payload, {
        context: new HttpContext()
          .set(IS_PUBLIC_API, true)
          .set(EXCLUDE_EXPIRED, true)
          .set(EXCLUDE_ERRORS, [400, 401, 403, 429])
      })
      .pipe(
        tap((tokens: TokenResponse): void => {
          const expirationTime = this.calculateExpirationTime(tokens.expiresIn);
          const refreshExpirationTime = this.calculateExpirationTime(tokens.refreshExpiresIn);
          this.cookiesService.setCookies([
            { name: Cookies.ACCESS_TOKEN, value: `${tokens.accessToken}; Expires=${expirationTime.getTime()}; path=/` },
            {
              name: Cookies.REFRESH_TOKEN,
              value: `${tokens.refreshToken}; Expires=${refreshExpirationTime.getTime()}; path=/`
            },
            {
              name: Cookies.EXPIRATION_TIME,
              value: `${expirationTime.getTime()}; Expires=${expirationTime.getTime()}; path=/`
            },
            {
              name: Cookies.REFRESH_EXPIRATION_TIME,
              value: `${refreshExpirationTime.getTime()}; Expires=${refreshExpirationTime.getTime()}; path=/`
            }
          ]);
          this.userIsLogged.next(true);
          this.initTimer(expirationTime.getTime());
        })
      );
  }

  logout(): void {
    this.cookiesService.removeCookies(
      Cookies.ACCESS_TOKEN,
      Cookies.REFRESH_TOKEN,
      Cookies.EXPIRATION_TIME,
      Cookies.REFRESH_EXPIRATION_TIME
    );
    this.timer$.next();
    this.userIsLogged.next(false);
  }

  private calculateExpirationTime(expiresIn: number): Date {
    const userTimezoneOffset = new Date().getTimezoneOffset() * MIN_IN_MS;
    const expirationOffset = expiresIn * SEC_IN_MS;
    return new Date(Date.now() + expirationOffset + userTimezoneOffset);
  }

  private initTimer(expirationTime: number): void {
    timer(expirationTime - EXPIRATION_TIME_SHIFT - Date.now() - new Date().getTimezoneOffset() * MIN_IN_MS)
      .pipe(
        switchMap(() => {
          const refreshToken = this.cookiesService.getCookie(Cookies.REFRESH_TOKEN);
          if (!refreshToken) {
            this.userIsLogged.next(false);
            return of(null);
          }
          return this.token({ refreshToken });
        })
      )
      .subscribe();
  }
}
