export enum FeedbackType {
  UNREAD,
  ALL
}

export const feedbackTypes = [FeedbackType.UNREAD, FeedbackType.ALL];

export const FEEDBACK_TYPE_NAMES = {
  [FeedbackType.UNREAD]: 'Unread',
  [FeedbackType.ALL]: 'All'
};
