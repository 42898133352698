import { AbstractControl, ValidationErrors } from '@angular/forms';

import {
  lengthValidationRule,
  lowercaseValidationRule,
  numberValidationRule,
  passwordKeys,
  specialCharacterValidationRule,
  uppercaseValidationRule
} from '@vvc/constants';

export function passwordValidator(control: AbstractControl): ValidationErrors | null {
  if (!control) {
    return null;
  }
  const errors = {} as ValidationErrors;
  if (!control.value.match(uppercaseValidationRule)) {
    errors[passwordKeys[0]] = true;
  }
  if (!control.value.match(lowercaseValidationRule)) {
    errors[passwordKeys[1]] = true;
  }
  if (!control.value.match(numberValidationRule)) {
    errors[passwordKeys[2]] = true;
  }
  if (!control.value.match(specialCharacterValidationRule)) {
    errors[passwordKeys[3]] = true;
  }
  if (!control.value.match(lengthValidationRule)) {
    errors[passwordKeys[4]] = true;
  }
  return Object.values(errors).length ? errors : null;
}
