import { Injectable } from '@angular/core';

import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly defaultErrorText = "We're sorry, something went wrong, please try again later";
  private readonly defaultErrorTitle = 'Error';
  private readonly defaultSuccessTitle = 'Success';

  constructor(private notification: NzNotificationService) {}

  error(title: string, text: string): void {
    this.notification.create('error', title || this.defaultErrorTitle, text || this.defaultErrorText);
  }

  success(text: string): void {
    this.notification.create('success', this.defaultSuccessTitle, text);
  }
}
