<div class="company-detail">
  <img
    alt="Ververica Symbol"
    class="company-detail__logo"
    routerLink="/home"
    src="/assets/img/ververica-symbol.svg"
  />
  <span class="company-detail__description">
    © {{ currentYear }} Ververica GmbH. All rights reserved.
  </span>
</div>
<div class="footer-menu">
  <ul class="footer-list">
    <li>
      <a target="_blank" href="https://www.ververica.com/privacy-policy">Privacy</a>
    </li>
    <li>
      <a target="_blank" href="https://www.ververica.com/imprint">Imprint</a>
    </li>
    <li>
      <a target="_blank" href="https://www.ververica.com/terms-of-service">Terms of Service</a>
    </li>
  </ul>
</div>
