import { NzProgressStatusType } from 'ng-zorro-antd/progress';

export type WorkspaceCategory = 'OK' | 'PROCESSING' | 'ERROR' | 'UNKNOWN';

export const WorkspaceStatusColor: { [key in WorkspaceCategory]: NzProgressStatusType } = {
  OK: 'success',
  PROCESSING: 'active',
  ERROR: 'exception',
  UNKNOWN: 'normal'
};
