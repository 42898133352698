export enum RequestType {
  PENDING = 1,
  REJECTED,
  ACCEPTED
}

export const requestTypes = [RequestType.PENDING, RequestType.REJECTED, RequestType.ACCEPTED];

export const REQUEST_TYPE_NAMES = {
  [RequestType.PENDING]: 'Pending',
  [RequestType.REJECTED]: 'Rejected',
  [RequestType.ACCEPTED]: 'Accepted'
};
