<nz-form-item *ngIf="control">
  <nz-form-label *ngIf="label" nzNoColon [nzRequired]="required" [nzFor]="id">
    {{ label }}
  </nz-form-label>
  <nz-form-control vvcTemplateBinder nzHasFeedback>
    <textarea
      [class.static]="!resizable"
      [style.height.px]="height"
      nz-input
      [nzSize]="nzSize"
      [formControl]="control"
      [id]="id"
      [name]="id"
    ></textarea>
  </nz-form-control>
</nz-form-item>
