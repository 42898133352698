import { Routes } from '@angular/router';

import { TITLE_PREFIX } from '@vvc/constants';
import { AuthGuard, NegateAuthGuard } from '@vvc/services';

export const APP_ROUTES: Routes = [
  {
    path: 'home',
    loadComponent: () => import('@vvc/routers/home/home.component').then(m => m.HomeComponent),
    title: `${TITLE_PREFIX}Try Ververica Cloud`,
    canMatch: [NegateAuthGuard]
  },
  {
    path: 'dashboard',
    loadComponent: () => import('@vvc/routers/dashboard/dashboard.component').then(m => m.DashboardComponent),
    title: `${TITLE_PREFIX}Dashboard`,
    canMatch: [AuthGuard]
  },
  {
    path: 'profile',
    loadComponent: () => import('@vvc/routers/profile/user-management.component').then(m => m.UserManagementComponent),
    title: `${TITLE_PREFIX}User Management`,
    canMatch: [AuthGuard]
  },
  {
    path: 'announcement',
    loadChildren: () =>
      import('@vvc/routers/latest-announcement/latest-announcement-routing').then(m => m.LATEST_ANNOUNCEMENT_ROUTES),
    title: `${TITLE_PREFIX}Latest Announcement`
  },
  {
    path: 'support-center',
    loadChildren: () => import('@vvc/routers/support-center/support-center-routing').then(m => m.SUPPORT_CENTER_ROUTES),
    title: `${TITLE_PREFIX}Support Center`,
    canMatch: [AuthGuard]
  },
  {
    path: 'faq',
    loadComponent: () => import('@vvc/routers/faq/faq.component').then(m => m.FaqComponent),
    title: `${TITLE_PREFIX}FAQ`
  },
  {
    path: 'privacy',
    loadComponent: () => import('@vvc/routers/privacy/privacy.component').then(m => m.PrivacyComponent),
    title: `${TITLE_PREFIX}Privacy Policy`
  },
  {
    path: 'authenticate',
    loadChildren: () => import('@vvc/routers/authenticate/authenticate-routing').then(m => m.AUTHENTICATE_ROUTES),
    canMatch: [NegateAuthGuard]
  },
  {
    path: 'site-terms',
    loadComponent: () => import('@vvc/routers/site-terms/site-terms.component').then(m => m.SiteTermsComponent),
    title: `${TITLE_PREFIX}Terms of Use`
  },
  {
    path: 'cookie-preference',
    loadComponent: () =>
      import('@vvc/routers/cookie-preference/cookie-preference.component').then(m => m.CookiePreferenceComponent),
    title: `${TITLE_PREFIX}Cookie Preference`
  },
  {
    path: 'create-service',
    loadComponent: () =>
      import('@vvc/routers/create-service/create-service.component').then(m => m.CreateServiceComponent),
    title: `${TITLE_PREFIX}Create Workspace`,
    canMatch: [AuthGuard]
  },
  {
    path: 'admin/verification',
    loadComponent: () =>
      import('@vvc/routers/sign-up-verification/sign-up-verification.component').then(
        m => m.SignUpVerificationComponent
      ),
    title: `${TITLE_PREFIX}Sign Up Requests`,
    canMatch: [AuthGuard]
  },
  {
    path: 'admin/feedbacks',
    loadChildren: () => import('@vvc/routers/feedback/feedback-routing').then(m => m.FEEDBACK_ROUTES),
    title: `${TITLE_PREFIX}Feedback`
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];
