import { NgIf } from '@angular/common';
import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import {
  AbstractVvcControlDirective,
  ControlTemplatesComponent,
  PhoneDirective,
  TemplateBinderDirective
} from '@vvc/components';
import { VVC_CONTROL_ACCESSOR } from '@vvc/constants';
import { InputValueType } from '@vvc/interfaces';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  selector: 'vvc-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
    {
      provide: VVC_CONTROL_ACCESSOR,
      useExisting: forwardRef(() => InputComponent)
    }
  ],
  imports: [
    NzFormModule,
    NzInputModule,
    NzPopoverModule,
    ReactiveFormsModule,
    NgIf,
    ControlTemplatesComponent,
    TemplateBinderDirective,
    PhoneDirective
  ],
  standalone: true
})
export class InputComponent extends AbstractVvcControlDirective<InputValueType> {
  @Input() type = 'text';

  constructor(injector: Injector) {
    super(injector);
  }
}
