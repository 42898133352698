import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
  standalone: true
})
export class EmptyValuePipe implements PipeTransform {
  transform(value: string | number | null | undefined, emptySymbol?: string): string | number {
    if (typeof value === 'undefined' || value === null || Number.isNaN(value)) {
      return emptySymbol ? emptySymbol : '-';
    }
    return value;
  }
}
