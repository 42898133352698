<nz-form-item *ngIf="control">
  <nz-form-label *ngIf="label" nzNoColon [nzRequired]="required" [nzFor]="id">
    {{ label }}
  </nz-form-label>
  <nz-form-control
    vvcTemplateBinder
    nzHasFeedback
    nz-popover
    [nzPopoverTrigger]="nzPopoverTrigger"
    [nzPopoverPlacement]="nzPopoverPlacement"
    [nzPopoverContent]="nzPopoverContent"
  >
    <input
      vvcPhone
      nz-input
      [nzSize]="nzSize"
      [placeholder]="nzPlaceHolder"
      [formControl]="control"
      [type]="type"
      [id]="id"
    />
  </nz-form-control>
</nz-form-item>
