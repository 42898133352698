import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchValidator(controlName: string, confirmControlName: string): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    let ctl = group.get(controlName);
    let confirmCtl = group.get(confirmControlName);
    if (confirmCtl?.errors && !confirmCtl?.errors['notSame']) {
      return null;
    }
    if (ctl?.value !== confirmCtl?.value) {
      confirmCtl?.setErrors({ notSame: true });
    } else {
      confirmCtl?.setErrors(null);
    }
    return null;
  };
}
