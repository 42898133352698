import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DO_NOTIFY_SUCCESS, IS_PUBLIC_API } from '@vvc/constants';
import { NewWorkspace, RegionResponse, Workspace, WorkspaceDTO } from '@vvc/interfaces';
import { UserService } from '@vvc/services/user.service';

const routes = {
  WORKSPACES: '/product/workspaces',
  WORKSPACE: '/product/workspaces/{WORKSPACE_ID}',
  WORKSPACE_PURCHASE: '/product/purchases',
  REGIONS: '/product/workspaces/availableRegions'
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private readonly removeSuccessMessage = 'Workspace deletion request submitted successfully!';
  private readonly createSuccessMessage = 'Your workspace has been successfully created.';

  constructor(private http: HttpClient, private userService: UserService) {}

  getWorkspaces(): Observable<Workspace[]> {
    const { userId } = this.userService;
    return this.http
      .get<WorkspaceDTO>(routes.WORKSPACES, {
        params: new HttpParams({ fromObject: { ...(userId && { userId }) } })
      })
      .pipe(map(response => response.workspaces ?? []));
  }

  deleteWorkspace(workspaceId: string): Observable<void> {
    return this.http.delete<void>(routes.WORKSPACE.replace('{WORKSPACE_ID}', workspaceId), {
      context: new HttpContext().set(DO_NOTIFY_SUCCESS, this.removeSuccessMessage)
    });
  }

  purchaseWorkspace(workspace: NewWorkspace): Observable<null> {
    return this.http.post<null>(routes.WORKSPACE_PURCHASE, workspace, {
      context: new HttpContext().set(DO_NOTIFY_SUCCESS, this.createSuccessMessage)
    });
  }

  fetchRegions(): Observable<RegionResponse> {
    return this.http.get<RegionResponse>(routes.REGIONS, { context: new HttpContext().set(IS_PUBLIC_API, true) });
  }
}
