import { maxInputLength, minInputLength } from './length-rules.constant';

export const uppercaseValidationRule = /[A-Z]/g;

export const lowercaseValidationRule = /[a-z]/g;

export const numberValidationRule = /\d/g;

export const specialCharacterValidationRule = /[\*\$\-\+\?\_\&\=\!\%\{\}\@]/g;

export const lengthValidationRule = new RegExp(`^.{${minInputLength},${maxInputLength}}$`, 'g');

export enum PasswordKeys {
  UPPERCASE = 'UPPERCASE',
  LOWERCASE = 'LOWERCASE',
  NUMBER = 'NUMBER',
  SPECIAL_CHARACTER = 'SPECIAL_CHARACTER',
  LENGTH = 'LENGTH'
}

export const passwordKeys = [
  PasswordKeys.UPPERCASE,
  PasswordKeys.LOWERCASE,
  PasswordKeys.NUMBER,
  PasswordKeys.SPECIAL_CHARACTER,
  PasswordKeys.LENGTH
];

export const passwordValidationRuleMessages = {
  [PasswordKeys.UPPERCASE]: 'at least one uppercase character',
  [PasswordKeys.LOWERCASE]: 'at least one lowercase character',
  [PasswordKeys.NUMBER]: 'at least one number character',
  [PasswordKeys.SPECIAL_CHARACTER]: 'at least one of * $ - + ? _ & = ! % { } @',
  [PasswordKeys.LENGTH]: `between ${minInputLength} to ${maxInputLength} characters`
};
