<ng-template #hintTemplate>
  <p class="hint" [innerHTML]="hint"></p>
</ng-template>

<ng-template #errorTips>
  <ng-container [ngSwitch]="controlError">
    <ng-container *ngFor="let error of errors; trackBy: identify">
      <ng-container *ngSwitchCase="error.key">
        {{ error.message.replace('{value}', control?.value || '') }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>{{ defaultErrorText }}</ng-container>
  </ng-container>
</ng-template>
