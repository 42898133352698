import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { FooterComponent, TopBarComponent, VerticalLayoutComponent } from '@vvc/components';

@Component({
  selector: 'vvc-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, VerticalLayoutComponent, TopBarComponent, FooterComponent]
})
export class AppComponent {}
