<nz-form-item *ngIf="control">
  <nz-form-label *ngIf="label" nzNoColon [nzRequired]="required" [nzFor]="id">
    {{ label }}
  </nz-form-label>
  <nz-form-control vvcTemplateBinder [nzValidateStatus]="validateStatus" nzHasFeedback>
    <input
      type="text"
      nz-co
      nz-input
      [formControl]="filter"
      [nzSize]="nzSize"
      [placeholder]="nzPlaceHolder"
      [id]="id"
      [nzAutocomplete]="auto"
    />
    <nz-autocomplete #auto>
      <nz-auto-option
        *ngFor="let option of filteredOptions; trackBy: trackByFn"
        [nzValue]="option.value"
        [nzLabel]="option.label"
      >
        {{ option.label }}
      </nz-auto-option>
    </nz-autocomplete>
  </nz-form-control>
  <input type="text" [hidden]="true" [formControl]="control" />
</nz-form-item>
