import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { InfoBlockItem } from '@vvc/interfaces';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'vvc-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.less'],
  standalone: true,
  imports: [AsyncPipe, RouterModule, NgForOf, NgIf, NzIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBlockComponent {
  @Input() titleIcon?: string;
  @Input() title!: 'Latest Announcement' | 'FAQ';
  @Input() data!: InfoBlockItem[];
  @Output() seeAll = new EventEmitter<void>();
  @Output() selectItem = new EventEmitter<string>();

  identify(_: number, { id }: InfoBlockItem): string {
    return id;
  }

  select({ id }: InfoBlockItem): void {
    this.selectItem.emit(id.toString());
  }

  expand(): void {
    this.seeAll.emit();
  }
}
