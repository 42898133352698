import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, last, Observable, tap } from 'rxjs';

import {
  authHeader,
  Cookies,
  DO_NOTIFY_SUCCESS,
  EXCLUDE_ERRORS,
  EXCLUDE_EXPIRED,
  IS_PUBLIC_API,
  RECAPTCHA_TOKEN,
  RECAPTCHA_TOKEN_HEADER,
  sessionHeader
} from '@vvc/constants';
import { AuthService, CookiesService, NotificationService } from '@vvc/services';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private cookiesService: CookiesService,
    private authService: AuthService,
    private router: Router,
    private notification: NotificationService
  ) {}

  intercept(request: HttpRequest<NzSafeAny>, next: HttpHandler): Observable<HttpEvent<NzSafeAny>> {
    const excludeExpired = request.context.get(EXCLUDE_EXPIRED);
    const successMessage = request.context.get(DO_NOTIFY_SUCCESS);
    const excludedErrors = request.context.get(EXCLUDE_ERRORS);

    let handle = this.handleErrorResponse(next.handle(this.setHeaders(request)), excludedErrors);

    if (!excludeExpired) {
      handle = this.handleTokenExpiration(handle);
    }

    if (successMessage) {
      handle = this.handleSuccessResponse(handle, successMessage);
    }

    return handle;
  }

  private setHeaders(request: HttpRequest<NzSafeAny>): HttpRequest<NzSafeAny> {
    const token = this.cookiesService.getCookie('access_token');
    const hasToken = !request.context.get(IS_PUBLIC_API);
    let req = hasToken ? this.addAuthenticationToken(request, token) : request;
    req = this.addSessionId(req);
    return this.addRecaptchaToken(req);
  }

  private addAuthenticationToken(request: HttpRequest<NzSafeAny>, token: string | null): HttpRequest<NzSafeAny> {
    return request.clone({
      headers: request.headers.set(authHeader, `Bearer ${token}`)
    });
  }

  private addSessionId(request: HttpRequest<NzSafeAny>): HttpRequest<NzSafeAny> {
    const sessionId = this.getSessionId();
    return request.clone({
      headers: request.headers.set(sessionHeader, sessionId)
    });
  }

  private addRecaptchaToken(request: HttpRequest<NzSafeAny>): HttpRequest<NzSafeAny> {
    const recaptchaToken = request.context.get(RECAPTCHA_TOKEN);
    return recaptchaToken
      ? request.clone({
          headers: request.headers.set(RECAPTCHA_TOKEN_HEADER, recaptchaToken)
        })
      : request;
  }

  private handleErrorResponse(handle: Observable<NzSafeAny>, excludedErrors: number[]): Observable<NzSafeAny> {
    return handle.pipe(
      catchError((err: HttpErrorResponse) => {
        if (!(err instanceof ErrorEvent) && !excludedErrors.includes(err.status)) {
          this.notification.error(err.error?.title, err.error?.detail);
        }
        throw err;
      })
    );
  }

  private handleSuccessResponse(handle: Observable<NzSafeAny>, message: string): Observable<NzSafeAny> {
    return handle.pipe(
      last(),
      tap(() => {
        this.notification.success(message);
      })
    );
  }

  private handleTokenExpiration(handle: Observable<NzSafeAny>): Observable<NzSafeAny> {
    return handle.pipe(
      catchError(err => {
        if (err.status === 401) {
          this.authService
            .token({ refreshToken: this.cookiesService.getCookie(Cookies.REFRESH_TOKEN) || '' })
            .pipe(
              catchError(err => {
                this.authService.logout();
                this.router.navigate(['/home']);
                throw err;
              })
            )
            .subscribe();
        }
        throw err;
      })
    );
  }

  private getSessionId(): string {
    const sessionId = sessionStorage.getItem('session-id');
    return sessionId ?? `${Date.now()}`;
  }
}
