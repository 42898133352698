import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  DO_NOTIFY_SUCCESS,
  EXCLUDE_ERRORS,
  IS_PUBLIC_API,
  RECAPTCHA_TOKEN,
  RequestType,
  statusParams,
  UserStatuses
} from '@vvc/constants';
import {
  UserResponse,
  SignUpRequestsResponse,
  SignUp,
  Option,
  CountriesResponse,
  IndustriesResponse
} from '@vvc/interfaces';
import { CookiesService } from '@vvc/services';
import { parseToken } from '@vvc/utils';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

const routes = {
  SIGN_UP: '/user/users',
  USER: '/user/users/{USER_ID}',
  USERS: '/user/users',
  STATUS: '/user/users/{USER_ID}/status',
  COUNTRIES: '/user/users/availableCountries',
  INDUSTRIES: '/user/users/availableIndustries'
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly successSignUpMessage =
    'Your sign-up request has been submitted. Please check your email for next steps.';

  get userId(): string | undefined {
    const token = this.cookiesService.getCookie('access_token');
    let parsedToken: NzSafeAny = {};
    try {
      parsedToken = parseToken(token);
    } catch (err) {}
    return parsedToken?.user_id;
  }

  constructor(private http: HttpClient, private cookiesService: CookiesService) {}

  getUser(id: string): Observable<UserResponse> {
    return this.http.get<UserResponse>(routes.USER.replace('{USER_ID}', id));
  }

  fetchRequests(type: RequestType, pageIndex = 0, pageSize = 10): Observable<SignUpRequestsResponse> {
    return this.http.get<SignUpRequestsResponse>(routes.USERS, {
      params: new HttpParams({
        fromObject: {
          statuses: statusParams[type],
          page: pageIndex,
          size: pageSize
        }
      })
    });
  }

  setStatus(userId: string, status: UserStatuses.APPROVED | UserStatuses.REJECTED): Observable<void> {
    return this.http.put<void>(routes.STATUS.replace('{USER_ID}', userId), { status });
  }

  signUp(data: SignUp, token: string): Observable<void> {
    return this.http.post<void>(routes.SIGN_UP, data, {
      context: new HttpContext()
        .set(IS_PUBLIC_API, true)
        .set(RECAPTCHA_TOKEN, token)
        .set(DO_NOTIFY_SUCCESS, this.successSignUpMessage)
        .set(EXCLUDE_ERRORS, [500])
    });
  }

  getCountries(): Observable<Option[]> {
    return this.http
      .get<CountriesResponse>(routes.COUNTRIES, {
        context: new HttpContext().set(IS_PUBLIC_API, true)
      })
      .pipe(map(({ countries }) => countries.map(({ id, name }) => ({ value: id, label: name }))));
  }

  getIndustries(): Observable<Option[]> {
    return this.http
      .get<IndustriesResponse>(routes.INDUSTRIES, {
        context: new HttpContext().set(IS_PUBLIC_API, true)
      })
      .pipe(map(({ industries }) => industries.map(({ id, text }) => ({ value: id, label: text }))));
  }
}
