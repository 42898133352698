import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { filter, map, Observable, race, take, tap } from 'rxjs';

export function inUseValidator(isValid: Observable<void>): AsyncValidatorFn {
  let prevVal = '';
  return (control: AbstractControl<string>): Observable<ValidationErrors | null> =>
    race([
      control.valueChanges.pipe(
        filter(val => val !== prevVal),
        tap(val => {
          prevVal = val;
        })
      ),
      isValid
    ]).pipe(
      map(val => (val ? null : { inUse: true })),
      take(1)
    );
}
