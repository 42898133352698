import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DO_NOTIFY_SUCCESS, FeedbackType } from '@vvc/constants';
import { Feedback, FeedbackAttachment, FeedbackPage } from '@vvc/interfaces';
import { UserService } from '@vvc/services';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzUploadFile } from 'ng-zorro-antd/upload';

export const supportRoutes = {
  FEEDBACK: '/support/userFeedbacks/{FEEDBACK_ID}',
  FEEDBACKS: '/support/userFeedbacks',
  CREATE_FEEDBACK: '/support/userFeedbacks',
  ATTACHMENTS: '/support/userFeedbacks/{FEEDBACK_ID}/attachments/{ATTACHMENTS_ID}'
};

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private readonly createSuccessMessage = 'Your feedback has been successfully created.';
  private readonly deleteNotificationMessage = 'Feedback successfuly deleted';
  constructor(private http: HttpClient, private userService: UserService) {}

  getUserFeedbacks(page = 0, size = 5, all = false, query?: string, unread?: FeedbackType): Observable<FeedbackPage> {
    const { userId } = this.userService;

    let params = new HttpParams({
      fromObject: {
        ...(!all && userId && { userId }),
        ...(query && { query }),
        ...(unread !== null && { unread: !Boolean(unread) }),
        page,
        size
      }
    });
    return this.http.get<FeedbackPage>(supportRoutes.FEEDBACKS, { params });
  }

  getUserFeedback(id: string): Observable<Feedback> {
    return this.http.get<Feedback>(supportRoutes.FEEDBACK.replace('{FEEDBACK_ID}', id));
  }

  getSingleFile(feedbackId: string, file: FeedbackAttachment): Observable<Blob> {
    return this.http.get<Blob>(
      `${supportRoutes.ATTACHMENTS.replace('{FEEDBACK_ID}', feedbackId).replace('{ATTACHMENTS_ID}', file.id)}`,
      {
        responseType: 'blob' as 'json'
      }
    );
  }

  createUserFeedback(title: string, content: string, files: NzUploadFile[]): Observable<void> {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    files.forEach((file: NzSafeAny) => {
      formData.append('file', file);
    });
    return this.http.post<void>(supportRoutes.CREATE_FEEDBACK, formData, {
      context: new HttpContext().set(DO_NOTIFY_SUCCESS, this.createSuccessMessage)
    });
  }

  deleteUserFeedback(id: string): Observable<void> {
    return this.http.delete<void>(supportRoutes.FEEDBACK.replace('{FEEDBACK_ID}', id), {
      context: new HttpContext().set(DO_NOTIFY_SUCCESS, this.deleteNotificationMessage)
    });
  }
}
