import { NgForOf, NgIf } from '@angular/common';
import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { AbstractVvcControlDirective, ControlTemplatesComponent, TemplateBinderDirective } from '@vvc/components';
import { VVC_CONTROL_ACCESSOR } from '@vvc/constants';
import { Option } from '@vvc/interfaces';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'vvc-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: VVC_CONTROL_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent)
    }
  ],
  imports: [
    NzFormModule,
    NzSelectModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    ControlTemplatesComponent,
    TemplateBinderDirective
  ],
  standalone: true
})
export class SelectComponent extends AbstractVvcControlDirective<NzSafeAny> {
  @Input() options: Option[] = [];
  @Input() trackByFn: (index: number, item: NzSafeAny) => NzSafeAny = (index: number) => {
    return index;
  };

  constructor(injector: Injector) {
    super(injector);
  }
}
