import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode: string = 'US'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validNumber = !control.value;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber) || !phoneNumber;
    } catch (e) {}
    return validNumber ? null : { wrongNumber: { value: control.value } };
  };
}
