import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EMPTY, fromEvent, interval, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, share, startWith, switchMap } from 'rxjs/operators';

import { APP_REFRESH_INTERVAL } from '@vvc/constants';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  readonly visibility$ = fromEvent(window, 'visibilitychange').pipe(map(e => !(e.target as Document).hidden));
  refresh$!: Observable<boolean>;
  private manualRefresh$ = new Subject<void>();
  private navigationEnd$ = this.router.events.pipe(
    filter(item => item instanceof NavigationEnd),
    map(() => true)
  );

  constructor(@Inject(APP_REFRESH_INTERVAL) private intervalTime: number, private router: Router) {}

  setup(): void {
    const interval$ = interval(this.intervalTime).pipe(
      startWith(true),
      map(() => true)
    );
    this.refresh$ = merge(this.visibility$, this.manualRefresh$.pipe(map(() => true)), this.navigationEnd$).pipe(
      startWith(true),
      debounceTime(300),
      switchMap(active => (active ? interval$ : EMPTY)),
      share()
    );
  }

  refresh(): void {
    this.manualRefresh$.next();
  }
}
