import { Pipe, PipeTransform } from '@angular/core';

export type Nth = 'st' | 'nd' | 'rd' | 'th';

@Pipe({
  name: 'nth',
  standalone: true
})
export class NthPipe implements PipeTransform {
  transform(value: string | null): string {
    return (
      (value && value.split(' ').length === 3 && value.replace(',', `${this.nth(parseInt(value.split(' ')[1]))},`)) ||
      ''
    );
  }

  nth(d: number): Nth {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
