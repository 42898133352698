import { map } from 'rxjs';

import { linkDetails } from '@vvc/constants';
import { LinkDetails } from '@vvc/interfaces';
import { PortalService } from '@vvc/services';

export abstract class AbstractDashboardDirective {
  faqItems$ = this.portalService.getFaqItems().pipe(map(res => res.items));
  announcements$ = this.portalService.getLatestAnnouncements().pipe(map(res => res.announcements));

  linkBlock: LinkDetails[] = linkDetails;

  constructor(private portalService: PortalService) {}

  identify(_: number, item: LinkDetails): string {
    return item.id;
  }
}
