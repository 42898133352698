<nz-page-header [nzGhost]="false">
  <nz-page-header-title>
    <img
      class="logo"
      (click)="redirectToMainPage()"
      src="/assets/img/logo.svg"
      alt="Ververica Logo"
    />
  </nz-page-header-title>
  <nz-page-header-extra>
    <div class="user-icon-block">
      <a
        *ngIf="isUserLogged | async"
        (click)="navigate(['/support-center/list'])"
        class="menu-action"
      >
        Contact Us
      </a>
      <span
        nz-icon
        nzType="menu"
        nzTheme="outline"
        class="hamburger-menu"
        (click)="toggleResponsiveMenu()"
        [class.opened]="isMenuMobileVisible"
      ></span>
      <span
        nz-icon
        nz-dropdown
        [nzType]="userIcon"
        class="user-icon"
        [class.opened]="isMenuVisible"
        [nzDropdownMenu]="menu"
        [nzOverlayStyle]="{
          left: '20px'
        }"
        [(nzVisible)]="isMenuVisible"
      ></span>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable class="submenu">
          <ng-container *ngIf="isUserLogged | async; else notLoggedMenu">
            <li nz-menu-item class="menu-item" [routerLink]="['/profile']">
              <a nz-button nzType="text">
                <span nz-icon nzType="img:settings" class="menu-icon"></span>
                User Management
              </a>
            </li>
            <li nz-menu-item class="menu-item" (click)="logout()">
              <a nz-button nzType="text">
                <span nz-icon nzType="img:log-out" class="menu-icon"></span>
                Log out
              </a>
            </li>
          </ng-container>
          <ng-template #notLoggedMenu>
            <li nz-menu-item class="menu-item" [routerLink]="['/authenticate/sign-up']">
              <a nz-button nzType="text">
                <span nz-icon nzType="img:sign-up" class="menu-icon"></span>
                Sign up
              </a>
            </li>
            <li nz-menu-item class="menu-item" [routerLink]="['/authenticate/login']">
              <a nz-button nzType="text">
                <span nz-icon nzType="img:log-in" class="menu-icon"></span>
                Log in
              </a>
            </li>
          </ng-template>
        </ul>
      </nz-dropdown-menu>

      <div class="container-responsive-menu" [class.active]="isMenuMobileVisible">
        <div class="dropdown-menu">
          <ul nz-menu>
            <ng-container *ngIf="isUserLogged | async; else notLoggedMenuResponsive">
              <li class="menu-item" (click)="navigate(['/support-center/list'])">
                <span nz-icon nzType="question-circle" class="menu-icon"></span>
                Support
                <span class="to-right" nz-icon nzType="right" nzTheme="outline"></span>
              </li>
              <li class="menu-item" (click)="navigate(['/profile'])">
                <span nz-icon nzType="img:settings" class="menu-icon"></span>
                User Management
                <span class="to-right" nz-icon nzType="right" nzTheme="outline"></span>
              </li>
              <li class="menu-item" (click)="logout()">
                <span nz-icon nzType="img:log-out" class="menu-icon"></span>
                Log out
                <span class="to-right" nz-icon nzType="right" nzTheme="outline"></span>
              </li>
            </ng-container>
            <ng-template #notLoggedMenuResponsive>
              <li class="menu-item" (click)="navigate(['/authenticate/sign-up'])">
                <span nz-icon nzType="img:sign-up" class="menu-icon"></span>
                Sign up
                <span class="to-right" nz-icon nzType="right" nzTheme="outline"></span>
              </li>
              <li class="menu-item" (click)="navigate(['/authenticate/login'])">
                <span nz-icon nzType="img:log-in" class="menu-icon"></span>
                Log in
                <span class="to-right" nz-icon nzType="right" nzTheme="outline"></span>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
    </div>
  </nz-page-header-extra>
</nz-page-header>
