import { NgForOf, NgIf } from '@angular/common';
import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs';

import { AbstractVvcControlDirective, ControlTemplatesComponent, TemplateBinderDirective } from '@vvc/components';
import { VVC_CONTROL_ACCESSOR } from '@vvc/constants';
import { Option } from '@vvc/interfaces';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

@Component({
  selector: 'vvc-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    },
    {
      provide: VVC_CONTROL_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent)
    }
  ],
  imports: [
    NzFormModule,
    NzInputModule,
    NzAutocompleteModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    TemplateBinderDirective,
    ControlTemplatesComponent
  ],
  standalone: true
})
export class AutocompleteComponent extends AbstractVvcControlDirective<NzSafeAny> implements OnInit {
  @Input() set options(opts: Option[]) {
    this._options = opts;
    const opt = this.options.find(opt => opt.value === this.control?.value);
    if (opt) {
      this.filter.setValue(opt.label);
    }
    this.control?.markAsUntouched();
  }

  @Input() trackByFn: (index: number, item: NzSafeAny) => NzSafeAny = (index: number) => {
    return index;
  };

  filter = new FormControl<string>('');

  private _options: Option[] = [];

  get options(): Option[] {
    return this._options;
  }

  get filteredOptions(): Option[] {
    return this.options.filter(option => option.label.toLowerCase().includes(this.filter.value?.toLowerCase() || ''));
  }

  constructor(injector: Injector) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.control?.disabled) {
      this.filter.disable();
    }
    this.initListeners();
  }

  override writeValue(val: NzSafeAny): void {
    super.writeValue(val);
    const opt = this.options.find(opt => opt.value === val);
    if (opt) {
      this.filter.setValue(opt.label);
    }
    this.control?.markAsUntouched();
  }

  private initListeners(): void {
    this.filter.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(value => {
          const opt = this.options.find(opt => opt.label.toLowerCase() === value?.toLowerCase() || opt.value === value);
          this.control?.setValue(opt?.value || null);
          if (opt && opt.label.toLowerCase() === value?.toLowerCase()) {
            this.filter.setValue(opt.label, { emitEvent: false });
          }
          this.control?.markAsTouched();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
