export const linkDetails = [
  {
    id: '1',
    img: 'wheel',
    title: 'Getting started with VVC',
    url: 'https://docs.ververica.cloud/guide/getting-started/quickstart-index.html',
    description: 'Learn the fundamentals and get the most out of VVC.'
  },
  {
    id: '2',
    img: 'medal',
    title: 'Training and Certification',
    url: 'https://www.ververica.com/training',
    description: 'Learn from VVC expert and expand your knowledge.'
  },
  {
    id: '3',
    img: 'bulb',
    title: "What's new with VVC?",
    url: 'https://www.ververica.com/blog',
    description: 'Discover new VVC features & services'
  }
];
