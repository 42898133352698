import { Directive, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NgModel
} from '@angular/forms';
import { distinctUntilChanged, filter, map, ReplaySubject, Subject, takeUntil, tap } from 'rxjs';

import { FormError } from '@vvc/interfaces';
import { NzSafeAny, NzSizeLDSType, NzValidateStatus } from 'ng-zorro-antd/core/types';
import { NzTooltipTrigger } from 'ng-zorro-antd/tooltip';

export interface TemplateParams {
  hint: string;
  errors: FormError[];
  defaultErrorText: string;
}

@Directive()
export abstract class AbstractVvcControlDirective<InputType>
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() label = '';
  @Input() nzSize: NzSizeLDSType = 'large';
  @Input() nzPlaceHolder = '';
  @Input() nzHasFeedback = false;
  @Input() hint = '';
  @Input() id = '';
  @Input() nzPopoverTrigger: NzTooltipTrigger = 'focus';
  @Input() nzPopoverPlacement = 'rightTop';
  @Input() nzPopoverContent?: TemplateRef<NzSafeAny>;
  @Input() set showError(state: boolean) {
    this._showError = state;
    if (state && this.control?.value) {
      this.control.markAsDirty();
      this.control.updateValueAndValidity({ onlySelf: true });
    }
  }
  @Input() defaultErrorText = '';
  @Input() errors: FormError[] = [];
  @Input() set required(isRequried: boolean | string) {
    this._required = isRequried === '' ? true : !!isRequried;
  }

  control?: FormControl;
  templateParams$ = new ReplaySubject<TemplateParams>(1);

  protected destroy$ = new Subject<void>();

  private _required = false;
  private _showError = true;

  onChange: (value: InputType | null) => void = () => {};
  onTouched: (value: InputType | null) => void = () => {};

  get required(): boolean {
    return this._required;
  }

  get validateStatus(): NzValidateStatus {
    return this.control?.touched ? (this.control?.errors ? 'error' : 'success') : '';
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.setComponentControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showError'] || changes['hint'] || changes['errors'] || changes['defaultErrorText']) {
      this.templateParams$.next({
        hint: this.hint,
        errors: this.errors,
        defaultErrorText: this.defaultErrorText
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.templateParams$.complete();
  }

  writeValue(value: InputType | null): void {}

  registerOnChange(fn: (value: InputType | null) => void): void {}

  registerOnTouched(fn: (value: InputType | null) => void): void {}

  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case NgModel: {
        const { control, update } = injectedControl as NgModel;

        this.control = control;

        this.control.valueChanges
          .pipe(
            tap(<T>(value: T) => update.emit(value)),
            takeUntil(this.destroy$)
          )
          .subscribe();
        break;
      }
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = this.control || ((injectedControl as FormControlDirective).form as FormControl);
        break;
      }
    }

    this.control.valueChanges
      .pipe(
        tap(val => !this._showError && this.control?.markAsPristine()),
        filter(val => !val && val !== 0),
        distinctUntilChanged(),
        map(val => val || null),
        takeUntil(this.destroy$)
      )
      .subscribe(val => this.control!.setValue(val));
  }
}
