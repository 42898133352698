import { Injectable } from '@angular/core';

import { COOKIE_NAME } from '@vvc/interfaces';

export interface Cookie {
  name: string;
  value: string;
}
@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  getCookie(cookieName = COOKIE_NAME): string | null {
    const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
    return match ? match[2] : null;
  }

  setCookie(cookieName = COOKIE_NAME, value?: string | boolean): void {
    document.cookie = `${cookieName}=${value}`;
  }

  setCookies(cookies: Cookie[]): void {
    cookies.forEach(({ name, value }: Cookie) => this.setCookie(name, value));
  }

  removeCookie(cookieName = COOKIE_NAME): void {
    this.setCookie(cookieName, `''; Expires=${new Date(0)}; path=/`);
  }

  removeCookies(...cookieNames: string[]): void {
    cookieNames.forEach(name => this.removeCookie(name));
  }
}
