import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { AbstractVvcControlDirective, ControlTemplatesComponent, TemplateBinderDirective } from '@vvc/components';
import { VVC_CONTROL_ACCESSOR } from '@vvc/constants';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

@Component({
  selector: 'vvc-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    },
    {
      provide: VVC_CONTROL_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent)
    }
  ],
  imports: [ReactiveFormsModule, NzFormModule, NzInputModule, NgIf, ControlTemplatesComponent, TemplateBinderDirective]
})
export class TextareaComponent extends AbstractVvcControlDirective<string> {
  @Input() resizable = false;
  @Input() height = 200;

  constructor(injector: Injector) {
    super(injector);
  }
}
