import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener } from '@angular/core';

import { ResponsiveService } from '@vvc/services/responsive.service';

@Component({
  selector: 'vvc-vertical-layout',
  templateUrl: './vertical-layout.component.html',
  styleUrls: ['./vertical-layout.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class VerticalLayoutComponent implements AfterViewInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.detectScreenSize((event.target as Window).innerWidth);
  }

  constructor(private responsiveService: ResponsiveService) {}

  ngAfterViewInit(): void {
    this.detectScreenSize();
  }

  private detectScreenSize(screenSize?: number): void {
    const size = screenSize || window.innerWidth;
    this.responsiveService.onResize(size);
  }
}
