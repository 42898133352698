import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthGuard } from '@vvc/services';

@Injectable({
  providedIn: 'root'
})
export class NegateAuthGuard implements CanMatch {
  constructor(private authGuard: AuthGuard, private router: Router) {}

  canMatch(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.authGuard.canMatch(route, segments) || this.router.navigate(['/dashboard']);
  }
}
