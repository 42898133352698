import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { UserIcon } from '@vvc/interfaces';
import { AuthService } from '@vvc/services';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';

@Component({
  selector: 'vvc-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, NzButtonModule, NzIconModule, NzPageHeaderModule, NzDropDownModule, NgIf, AsyncPipe]
})
export class TopBarComponent implements OnInit {
  isMenuVisible = false;
  isMenuMobileVisible = false;

  get isUserLogged(): BehaviorSubject<boolean> {
    return this.authService.isUserLogged$;
  }

  get userIcon(): UserIcon {
    return `img:${this.isUserLogged.value ? '' : 'un'}logged`;
  }

  constructor(private router: Router, public authService: AuthService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationStart => event instanceof NavigationStart),
        tap(() => this.toggleResponsiveMenu(false))
      )
      .subscribe();
  }

  toggleResponsiveMenu(value?: boolean): void {
    this.isMenuMobileVisible = value ?? !this.isMenuMobileVisible;
    this.cdr.markForCheck();
  }

  logout(): void {
    this.toggleResponsiveMenu(false);
    this.authService.logout();
    this.router.navigate(['/home']);
  }

  navigate(route: string[]): void {
    this.toggleResponsiveMenu(false);
    this.router.navigate(route);
  }

  redirectToMainPage(): void {
    if (this.isUserLogged.value) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
