import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';

@Component({
  selector: 'vvc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, NzIconModule, NzPageHeaderModule, CommonModule]
})
export class NavbarComponent {
  @Input() navigateLink: string[] = ['../../home'];
  @Input() navigateLabel: string = 'Home';
  @Input() hasBackButton = true;
}
