<nz-form-item *ngIf="control">
  <nz-form-label *ngIf="label" nzNoColon [nzRequired]="required" [nzFor]="id">
    {{ label }}
  </nz-form-label>
  <nz-form-control vvcTemplateBinder [nzValidateStatus]="validateStatus" nzHasFeedback>
    <nz-select [nzSize]="nzSize" [formControl]="control" [id]="id" [nzPlaceHolder]="nzPlaceHolder">
      <nz-option
        *ngFor="let option of options; trackBy: trackByFn"
        [nzValue]="option.value"
        [nzLabel]="option.label"
      ></nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
