import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DEFAULT_INFO_PAGE, DEFAULT_INFO_SIZE, IS_PUBLIC_API } from '@vvc/constants';
import { Announcement, AnnouncementPage, FaqItemResponse } from '@vvc/interfaces';

const routes = {
  LATEST_ANNOUNCEMENTS: '/portal/announcements',
  LATEST_ANNOUNCEMENT: '/portal/announcements/{ANNOUNCEMENT_ID}',
  FAQ_ITEMS: '/portal/faqItems'
};

@Injectable({
  providedIn: 'root'
})
export class PortalService {
  constructor(private http: HttpClient) {}

  getLatestAnnouncements(
    page = DEFAULT_INFO_PAGE,
    size = DEFAULT_INFO_SIZE,
    query?: string
  ): Observable<AnnouncementPage> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('size', size);
    if (query) {
      params = params.append('searchPhrase', query);
    }
    return this.http.get<AnnouncementPage>(routes.LATEST_ANNOUNCEMENTS, {
      params,
      context: new HttpContext().set(IS_PUBLIC_API, true)
    });
  }

  getLatestAnnouncement(announcementId: string): Observable<Announcement> {
    return this.http.get<Announcement>(
      routes.LATEST_ANNOUNCEMENT.replace('{ANNOUNCEMENT_ID}', announcementId.toString()),
      {
        context: new HttpContext().set(IS_PUBLIC_API, true)
      }
    );
  }

  getFaqItems(page = DEFAULT_INFO_PAGE, size = DEFAULT_INFO_SIZE): Observable<FaqItemResponse> {
    return this.http
      .get<FaqItemResponse>(routes.FAQ_ITEMS, {
        params: new HttpParams().appendAll({ page, size }),
        context: new HttpContext().set(IS_PUBLIC_API, true)
      })
      .pipe(map((data: FaqItemResponse) => ({ ...data, items: data.items.map(item => ({ ...item, active: false })) })));
  }
}
