<nz-page-header>
  <nz-page-header-title *ngIf="hasBackButton">
    <a nz-button nzType="link" [routerLink]="navigateLink" class="header-back">
      <span nz-icon nzType="img:arrow-left" class="header-icon"></span>
      {{ navigateLabel }}
    </a>
  </nz-page-header-title>
  <nz-page-header-extra class="page-name-block">
    <h3 class="page-name">
      <ng-content></ng-content>
    </h3>
  </nz-page-header-extra>
</nz-page-header>
