import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';

import { ResponsiveBreakPoint } from '@vvc/constants';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  get windowWidth$(): Observable<number> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  get windowWidth(): number {
    return this.resizeSubject.value;
  }

  get showForMobile(): boolean {
    return this.windowWidth < ResponsiveBreakPoint.MD;
  }

  get isTablet(): boolean {
    return this.windowWidth > ResponsiveBreakPoint.MD && this.windowWidth < ResponsiveBreakPoint.LG;
  }

  private resizeSubject: BehaviorSubject<number>;

  constructor() {
    this.resizeSubject = new BehaviorSubject(window.innerWidth);
  }

  onResize(size: number): void {
    this.resizeSubject.next(size);
  }
}
