<vvc-vertical-layout>
  <header header class="vvc-header">
    <vvc-top-bar></vvc-top-bar>
  </header>
  <content content class="vvc-content">
    <router-outlet></router-outlet>
  </content>
  <footer footer class="footer">
    <vvc-footer></vvc-footer>
  </footer>
</vvc-vertical-layout>
