import { NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormError } from '@vvc/interfaces';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'vvc-control-templates',
  templateUrl: './control-templates.component.html',
  styleUrls: ['./control-templates.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault]
})
export class ControlTemplatesComponent {
  @ViewChild('hintTemplate', { static: true }) hintTemplate?: TemplateRef<NzSafeAny>;
  @ViewChild('errorTips', { static: true }) errorTips?: TemplateRef<NzSafeAny>;

  hint = '';
  control?: FormControl;
  defaultErrorText = '';
  errors: FormError[] = [];

  get controlError(): string {
    return this.control?.errors ? Object.keys(this.control.errors)[0] : '';
  }

  constructor(public changeDetector: ChangeDetectorRef) {}

  identify(index: number): number {
    return index;
  }
}
